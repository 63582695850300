import React from 'react'

import { twcn } from '@util/tw'

const Card = ({ children = null, wrap = '', card = '', ...rest }) => (
  <div className={twcn('w-full p-2', wrap)}>
    <div
      className={twcn('border rounded shadow p-3 h-full bg-white', card)}
      {...rest}
    >
      {children}
    </div>
  </div>
)

export default Card
