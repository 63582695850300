import React from 'react'

import PriceCard, { Feature } from '@components/pricing/PriceCard'
import LandingLayout from '@layouts/Landing'
import { Container, H1 } from '@layouts/Landing/components'
import SEO from '@components/seo'

const Pricing = () => {
  return (
    <LandingLayout>
      <SEO
        title="Simple pricing. First week is on us."
        description="No credit card required. Try it no-commitment for free and see how much easier understanding customer communications becomes."
      />
      <main>
        <div className="bg-blue-400">
          <Container className="flex-col">
            <H1 className="text-center">
              Simple pricing. First week is on us.
            </H1>

            <div className="flex flex-wrap mt-6">
              <PriceCard
                size="Small"
                prices={[39, 49]}
                features={
                  <>
                    <Feature>1 source</Feature>
                    <Feature>250 conversations / mo</Feature>
                    <Feature>Past 14-days history</Feature>
                    <Feature>Daily sync</Feature>
                  </>
                }
              />
              <PriceCard
                size="Medium"
                prices={[79, 99]}
                features={
                  <>
                    <Feature>2 sources</Feature>
                    <Feature>1,000 conversations / mo</Feature>
                    <Feature>Past month history</Feature>
                    <Feature>Daily sync</Feature>
                  </>
                }
              />
              <PriceCard
                size="Large"
                prices={[149, 199]}
                features={
                  <>
                    <Feature>All sources</Feature>
                    <Feature>5,000 conversations / mo</Feature>
                    <Feature>Past 6-month history</Feature>
                    <Feature>Daily sync</Feature>
                  </>
                }
              />
              <PriceCard
                size="Custom"
                prices={['400+', '1200+']}
                features={
                  <>
                    <Feature>Unlimited sources</Feature>
                    <Feature>Up to 100k conversations / mo</Feature>
                    <Feature>Infinite chat history</Feature>
                    <Feature>Choose sync frequency</Feature>
                  </>
                }
              />
            </div>

            <p className="text-blue-200 text-center mt-6 line-through">
              Try the Small plan free for 7 days • No credit card required •
              Prices in USD
            </p>
            <p className="text-blue-200 text-center mt-6">
              Free while in beta!
            </p>
          </Container>
        </div>

        {/* <div>
          <Container>
            <h2>Features</h2>
          </Container>
        </div> */}
      </main>
    </LandingLayout>
  )
}

export default Pricing
