import React from 'react'
import cn from 'classnames'
import { FaCheck } from 'react-icons/fa'
import { Link } from 'gatsby'

import Card from '@components/Card'
import { twcn } from '@util/tw'

export const Feature = ({ children }) => (
  <li className="flex items-center pl-2">
    <FaCheck className="text-green-400 mr-2" />
    {children}
  </li>
)

const PriceCard = ({
  size,
  prices,
  features,
  bottom = (
    <Link
      to="/app/signup"
      className={cn(
        'block p-2 mt-auto',
        'border border-transparent rounded',
        'text-center',
        'text-white',
        'bg-green-400 hover:bg-green-300',
      )}
    >
      {/* Join free for 7 days */}
      Join for free while in Beta
    </Link>
  ),
  card = {
    card: '',
    wrap: '',
  },
  top = null,
  ...rest
}) => (
  <Card
    wrap={twcn('md:w-1/4', card.wrap)}
    card={twcn('text-black rounded-lg flex flex-col', card.card)}
    {...rest}
  >
    {top}
    <p className="text-center font-semibold">{size}</p>
    <div className="flex items-center justify-center">
      <span>$</span>
      <span className="text-5xl font-semibold mx-1">{prices[0]}</span>
      <span>/ m</span>
    </div>
    <p className="text-sm text-gray-600 text-center mb-4">
      Billed annually or ${prices[1]} billed monthly
    </p>
    <ul className="mb-6">
      {features}
      <Feature>+ All standard features</Feature>
    </ul>
    {bottom}
  </Card>
)

export default PriceCard
